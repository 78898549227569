<template>
    <div>
        <v-stepper class="elevation-0" v-model="currentStep">
            <v-stepper-header
                :style="{ 'backgroundColor': isThemeAja ? '#1E3945' : $vuetify.theme.dark ? '#1E1E1E' : 'white' }"
                color="primary">
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="currentStep > 1" step="1">
                    Orientation
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="currentStep > 2" step="2">
                    Formulaire d'inscription
                </v-stepper-step>

                <v-divider v-if="isLigue || isRetreat || isPatientsreseau"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="currentStep > 3"
                                v-if="isLigue || isRetreat || isPatientsreseau" step="3">
                    Code adhérent
                </v-stepper-step>

                <v-divider v-if="isAjaYoung"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="currentStep > 3"
                                v-if="isAjaYoung"
                                step="3">
                    Informations du parent ou aidant
                </v-stepper-step>

                <v-divider v-if="isAjaAssistant"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="currentStep > 3"
                                v-if="isAjaAssistant"
                                step="3">
                    Informations de l'enfant
                </v-stepper-step>

                <v-divider :color="isThemeAja ? '#4E983C' : 'primary'"
                           v-if="isLigue || isAja || isPatientsreseau"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isLigue || isPatientsreseau || isAja"
                                step="4">
                    Questionnaire de profil
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items
                :style="{ 'backgroundColor': isThemeAja ? '#1E3945' : $vuetify.theme.dark ? '#1E1E1E' : 'white' }">
                <!-- STEP 1 : User registration reason -->
                <v-stepper-content step="1">
                    <OrientationForm @submitOrientation="handleSubmitOrientationForm" />
                </v-stepper-content>

                <!-- STEP 2 : User registration form -->
                <v-stepper-content step="2">
                    <UserRegistrationForm v-if="currentStep === 2" :orientation="registrationProcess"
                                          @goStepBack="handleGoStepBack"
                                          @submitUserRegistrationForm="handleSubmitUserRegistrationForm" />
                </v-stepper-content>

                <!-- STEP 3 : For Ligue or Retreat : adhCodeForm -->
                <v-stepper-content v-if="isLigue || isRetreat || isPatientsreseau" step="3">
                    <AdhCodeForm v-if="currentStep === 3"
                                 :orientation="registrationProcess"
                                 @submitAdhCodeForm="handleSubmitAdhCodeForm"
                                 @goStepBack="handleGoStepBack" />
                    <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="modifyReason()">
                        Modifier mon orientation
                    </v-btn>
                </v-stepper-content>

                <!-- STEP 3 : For Aja Young -->
                <v-stepper-content v-if="isAjaYoung" step="3">
                    <AjaYoungForm v-if="currentStep === 3" :user="user"
                                  @submitAjaYoungForm="handleSubmitAjaYoungForm"
                                  @goStepBack="handleGoStepBack" />
                    <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="modifyReason()">
                        Modifier mon orientation
                    </v-btn>
                </v-stepper-content>

                <!-- STEP 3 : For Aja Assistant -->
                <v-stepper-content v-if="isAjaAssistant" step="3">
                    <AjaAssistantForm v-if="currentStep === 3" :user="user"
                                      @submitAjaAssistantForm="handleSubmitAjaAssistantForm" />
                    <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="modifyReason()">
                        Modifier mon orientation
                    </v-btn>
                </v-stepper-content>

                <!-- STEP 4 : QR Profil -->
                <v-stepper-content v-if="isLigue || isPatientsreseau || isAjaAssistant || isAjaYoung" step="4">
                    <v-card v-if="currentStep === 4" flat class="mb-12">
                        <Survey v-model="survey" :from_registration="true" @saved="handleSubmitSurvey" />
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import OrientationForm from '@/components/registerSteps/OrientationForm'
import UserRegistrationForm from '@/components/registerSteps/UserRegistrationForm'
import AdhCodeForm from '@/components/registerSteps/AdhCodeForm'
import AjaYoungForm from '@/components/registerSteps/AjaYoungForm'
import AjaAssistantForm from '@/components/registerSteps/AjaAssistantForm'
import Survey from '@/components/surveys/Form'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        OrientationForm,
        UserRegistrationForm,
        AdhCodeForm,
        AjaYoungForm,
        AjaAssistantForm,
        Survey
    },
    data () {
        return {
            currentStep: 1,
            user: this.$store.getters.getProfile,
            registrationProcess: this.$store.getters.getRegistrationProcess,
            survey: {},
            changeReasonDialog: false
        }
    },
    computed: {
        ...mapGetters(['isThemeAja']),
        isLigue () {
            return this.registrationProcess.reason === 'ligue'
        },
        isLigueCodeMissing () {
            return this.isLigue && !this.registrationProcess.validLigueCodeRegistered
        },
        isLigueQPRMissing () {
            return this.isLigue && !this.registrationProcess.qprRegistered
        },
        isRetreat () {
            return this.registrationProcess.reason === 'retreat'
        },
        isRetreatCodeMissing () {
            return this.isRetreat && !this.registrationProcess.validRetreatCodeRegistered
        },
        isPatientsreseau () {
            return this.registrationProcess.reason === 'patientsreseau'
        },
        isPatientsreseauCodeMissing () {
            return this.isPatientsreseau && !this.registrationProcess.validPatientsreseauCodeRegistered
        },
        isPatientsreseauQPRMissing () {
            return this.isPatientsreseau && !this.registrationProcess.qprPatientsreseauRegistered
        },
        isAja () {
            return this.registrationProcess.reason === 'aja'
        },
        isAjaYoung () {
            return this.isAja && this.registrationProcess.aja_role === 'aja_young'
        },
        isAjaYoungFormMissing () {
            return this.isAjaYoung && !this.registrationProcess.ajaYoungFormRegistered
        },
        isAjaYoungQPRMissing () {
            return this.isAjaYoung && !this.registrationProcess.qprAjaYoungRegistered
        },
        isAjaAssistant () {
            return this.isAja && this.registrationProcess.aja_role === 'aja_assistant'
        },
        isAjaAssistantFormMissing () {
            return this.isAjaAssistant && parseInt(this.user.aja_assisted_nb) === 0 && !this.registrationProcess.ajaAssistantFormRegistered
        },
        isAjaAssistantQPRMissing () {
            return this.isAjaAssistant && !this.registrationProcess.qprAjaAssistantRegistered
        }
    },
    mounted () {
        this.setThemeAja(this.isAja)
        this.redirectUser()
    },
    methods: {
        ...mapActions(['setThemeAja']),
        async redirectUser () {
            if (!this.registrationProcess?.reason) {
                this.currentStep = 1
                return
            }
            if (!this.user.id) {
                this.currentStep = 2
                return
            }
            if (this.isLigueCodeMissing
                || this.isRetreatCodeMissing
                || this.isPatientsreseauCodeMissing
                || this.isAjaYoungFormMissing
                || this.isAjaAssistantFormMissing) {
                this.currentStep = 3
                return
            }
            if (this.isLigueQPRMissing || this.isPatientsreseauQPRMissing) {
                await this.fetchSurvey('profile')
                this.currentStep = 4
                return
            }
            if (this.isAjaYoungQPRMissing) {
                await this.fetchSurvey('profile_aja_young')
                this.currentStep = 4
                return
            }
            if (this.isAjaAssistantQPRMissing) {
                await this.fetchSurvey('profile_aja_assistant')
                this.currentStep = 4
                return
            }
            if (this.isRetreat || this.isLigue || this.isAja) {
                await this.$router.push({ name: 'payment', query: { subscription: '1' } })
                return
            }
            await this.$router.push({ name: 'welcome' })
        },
        async modifyReason () {
            await this.updateRegistrationProcess({ reason: '', partner: {} })
            this.currentStep = 1
        },
        async updateRegistrationProcess (registrationProcess) {
            if (!this.user.id) {
                // no user id, only local update
                return this.$store.dispatch('updateRegistrationProcess', registrationProcess)
            }

            // user id available, update via API
            this.user.registration_process = registrationProcess
            return this.$store.dispatch('userUpdateRegistrationProcessRequest', {
                id: this.user?.id,
                data: registrationProcess
            })
        },

        async fetchSurvey (ref) {
            this.$store.dispatch('surveyByRefRequest', { ref: ref, with: ['survey_questions'] })
                .then(result => {
                    this.survey = result.data
                })
                .catch((err) => {
                    if (err.response.status !== 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },

        async handleSubmitOrientationForm (registrationProcess) {
            await this.updateRegistrationProcess(registrationProcess)
            this.registrationProcess = this.$store.getters.getRegistrationProcess
            await this.redirectUser()
        },

        async handleSubmitUserRegistrationForm () {
            this.user = this.$store.getters.getProfile
            await this.redirectUser()
        },

        async handleSubmitAdhCodeForm (registrationProcess) {
            if (this.isLigue) {
                await this.updateRegistrationProcess({
                    ...this.registrationProcess,
                    validLigueCodeRegistered: true,
                    partner: registrationProcess.partner
                })
            } else if (this.isPatientsreseau) {
                await this.updateRegistrationProcess({
                    ...this.registrationProcess,
                    validPatientsreseauCodeRegistered: true,
                    partner: registrationProcess.partner
                })
            } else {
                await this.updateRegistrationProcess({
                    ...this.registrationProcess,
                    validRetreatCodeRegistered: true,
                    partner: registrationProcess.partner
                })
            }
            this.registrationProcess = this.$store.getters.getRegistrationProcess
            await this.redirectUser()
        },

        async handleSubmitAjaYoungForm () {
            await this.updateRegistrationProcess({ ...this.registrationProcess, ajaYoungFormRegistered: true })
            this.registrationProcess = this.$store.getters.getRegistrationProcess
            await this.redirectUser()
        },

        async handleSubmitAjaAssistantForm () {
            await this.updateRegistrationProcess({ ...this.registrationProcess, ajaAssistantFormRegistered: true })
            this.registrationProcess = this.$store.getters.getRegistrationProcess
            await this.redirectUser()
        },

        async handleSubmitSurvey () {
            if (this.isLigue) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprRegistered: true })
            } else if (this.isPatientsreseau) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprPatientsreseauRegistered: true })
            } else if (this.isAjaAssistant) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprAjaAssistantRegistered: true })
            } else if (this.isAjaYoung) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprAjaYoungRegistered: true })
            }

            this.registrationProcess = this.$store.getters.getRegistrationProcess

            await this.redirectUser()
        },

        handleGoStepBack (step) {
            this.currentStep = step
        }
    }
}
</script>
